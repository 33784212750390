import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import 'moment/locale/da';
import 'moment/locale/en-gb';

import {
  string, number, func, object,
} from 'prop-types';

import Slider from '../Slider/Slider';

import { useMedia } from '../../utils/hooks';
import { BASE_URL } from '../../constants';

import './Exhibitions.scss';


const Exhibitions = ({
  id,
  selectedExhibitionId,
  setSelectedExhibitionId,
  name,
  title,
  type,
  dateFrom,
  dateTo,
  description,
  subHeader,
  descriptionBlock2,
  subDescription,
  listOfPeople,
  pictures,
  pdfFiles,
  staticText,
  links,
  language,
}) => {
  if (language) {
      if (language === "da"){
          moment.locale("da")
      } else {
          moment.locale("en-gb")
      }
  }
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  let pdfFilesMapped = [];
  if (pdfFiles.data != null) {
    pdfFilesMapped = pdfFiles.data.map(d => ({ ...d.attributes, id: d.id }));
  }

  const isMobileVersion = useMedia(
    ['(min-width: 768px)', '(min-width: 280px)'],
    [false, true],
  );

  useEffect(() => {
    if (selectedExhibitionId === id) {
      ref.current.scrollIntoView();
    }
    setSelectedExhibitionId(null);
  });

  return (
    <div ref={ref} className="exhibition">

      <div className="exhibition__info">
        <div className="exhibition__title-container">
          {name && (
            <span
              className="exhibition__name"
              dangerouslySetInnerHTML={{ __html: `${name}, ` }}
            />
          )}
          <span
            className="exhibition__title"
            dangerouslySetInnerHTML={{ __html: title || '(untitled)' }}
          />
        </div>

        <div className="exhibition__subtitle-container">
          {dateFrom && (
            <span className="exhibition__date">
              {moment(new Date(dateFrom), 'YYYY-MM-DD').format('MMM D')}
              {dateTo && ` — ${moment(new Date(dateTo), 'YYYY-MM-DD').format('MMM D, YYYY')}`}
            </span>
          )}
        </div>

        {!!pdfFilesMapped.length && (
          <div className="exhibition__pdf-files-container">
            {pdfFilesMapped.map((file) => (
              <h3 key={file.id} className="exhibition__pdf-file">
                <a
                  href={`${BASE_URL}${file.url}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {file.caption || file.name.replace(/\.[^/.]+$/, '')}
                </a>
              </h3>
            ))}
          </div>
        )}

        {!!links.length && (
          <div className="exhibition__pdf-files-container">
            {links.map((link) => (
              <h3 key={link.id} className="exhibition__pdf-file">
                <a
                  href={`${link.linkHref}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {link.linkTitle}
                </a>
              </h3>
            ))}
          </div>
        )}

        {isMobileVersion ? (
          <div className="exhibition__content-container">
            <div className="exhibition__slider-container">
              <Slider pictures={pictures} />
            </div>
          </div>
        ) : (
          <>
            {description && (
              <h2
                className="exhibition__description"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}

            {subHeader && (
                <div className="exhibition__subHeader"  dangerouslySetInnerHTML={{ __html: subHeader}}>
                </div>
            )}


            {descriptionBlock2 && (
              <h2
                className="exhibition__description"
                dangerouslySetInnerHTML={{ __html: descriptionBlock2 }}
              />
            )}



            {subDescription && (
              <div className="exhibition__list">
                <div className="exhibition__btn-container">
                  <button
                    type="button"
                    onClick={() => setIsOpen((prevState) => !prevState)}
                  >
                    {isOpen ? (
                      <span className="read-less">{staticText.exhibitionReadLess}</span>
                    ) : (
                      <span className="read-more">{staticText.exhibitionReadMore}</span>
                    )}
                  </button>
                </div>
                {!isOpen && (
                  <>
                    {listOfPeople && (
                      <h3
                        className="exhibition__list-of-people"
                        dangerouslySetInnerHTML={{ __html: listOfPeople }}
                      />
                    )}
                  </>
                )}
                {isOpen && (
                  <div className="exhibition__list-container">
                    <h3
                      className="exhibition__sub-description"
                      dangerouslySetInnerHTML={{ __html: subDescription }}
                    />
                    {listOfPeople && (
                      <h3
                        className="exhibition__list-of-people"
                        dangerouslySetInnerHTML={{ __html: listOfPeople }}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>


      <div className="exhibition__content">
        {isMobileVersion ? (
          <>
            {description && (
              <h2
                className="exhibition__description"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}

            {subHeader && (
                <div className="exhibition__subHeader" dangerouslySetInnerHTML={{ __html: subHeader}}>
                </div>
            )}

            {descriptionBlock2 && (
              <h2
                className="exhibition__description"
                dangerouslySetInnerHTML={{ __html: descriptionBlock2 }}
              />
            )}


            {subDescription && (
              <div className="exhibition__list">
                <div className="exhibition__btn-container">
                  <button
                    type="button"
                    onClick={() => setIsOpen((prevState) => !prevState)}
                    style={{ ...(!listOfPeople && !isOpen && { marginBottom: 0 }) }}
                  >
                    {isOpen ? (
                      <span className="read-less">{staticText.exhibitionReadLess}</span>
                    ) : (
                      <span className="read-more">{staticText.exhibitionReadMore}</span>
                    )}
                  </button>
                </div>
                {!isOpen && (
                  <>
                    {listOfPeople && (
                      <h3
                        className="exhibition__list-of-people"
                        dangerouslySetInnerHTML={{ __html: listOfPeople }}
                      />
                    )}
                  </>
                )}
                {isOpen && (
                  <div className="exhibition__list-container">
                    <h3
                      className="exhibition__sub-description"
                      dangerouslySetInnerHTML={{ __html: subDescription }}
                    />
                    {listOfPeople && (
                      <h3
                        className="exhibition__list-of-people"
                        dangerouslySetInnerHTML={{ __html: listOfPeople }}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <div className="exhibition__content-container">
            <div className="exhibition__slider-container">
              <Slider pictures={pictures} />
            </div>
          </div>
        )}
      </div>

    </div>
  );
};

Exhibitions.propTypes = {
  id: number, 
  selectedExhibitionId: number,
  setSelectedExhibitionId: func.isRequired,
  name: string,
  title: string,
  type: string,
  dateFrom: string,
  dateTo: string,
  description: string,
  subHeader: string,
  descriptionBlock2: string,
  subDescription: string,
  listOfPeople: string,
  pictures: object, 
  pdfFiles: object, 
};

Exhibitions.defaultProps = {
  id: null,
  selectedExhibitionId: null,
  name: '',
  title: '',
  type: '',
  dateFrom: '',
  dateTo: '',
  description: '',
  subHeader: '',
  descriptionBlock2: '',
  subDescription: '',
  listOfPeople: '',
  pictures: {},
  pdfFiles: {}, 
};

export default Exhibitions;
