import { useState, useEffect } from 'react';
import axios from 'axios';

import {
  API_URL,
  DANISH,
  ENGLISH,
  DEFAULT_LANGUAGE,
  FIVE_SECONDS,
} from '../constants';

const parseCountryCode = (countryCode) => {
  switch (countryCode) {
  case 'DK':
    return DANISH;
  case 'FR':
  case 'GB':
    return ENGLISH;
  default:
    return DEFAULT_LANGUAGE;
  }
};

export const useFetchLanguage = () => {
  const [language, setLanguage] = useState();

  useEffect(() => {
    axios.get('https://geolocation-db.com/json/', { timeout: FIVE_SECONDS })
      .then((response) => {
        const ipData = response.data;
        setLanguage(parseCountryCode(ipData.country_code));
      })
      .catch((error) => {
        console.log('error', error);
        setLanguage(DEFAULT_LANGUAGE);
      });
  }, []);

  return [language, setLanguage];
};

export const useFetchEvents = (language) => {
  const [events, setEvents] = useState([]);
  const [eventsError, setEventsError] = useState(false);

  useEffect(() => {
    if (language) {
      axios.get(`${API_URL}/events?locale=${language}&populate=*`)
        .then((response) => {
            let events = response.data.data.map(d => ({ ...d.attributes, id: d.id }));
              setEvents(events)
        })
        .catch((error) => {
          console.log('error', error);
          setEventsError(true);
        });
    }
  }, [language]);

  return [events, eventsError];
};

export const useFetchExhibitions = (language) => {
  const [exhibitions, setExhibitions] = useState([]);
  const [exhibitionsError, setExhibitionsError] = useState(false);

  useEffect(() => {
    if (language) {
      axios.get(`${API_URL}/exhibitions?locale=${language}&populate=*`)
        .then((response) => {
          let exhibitions = response.data.data.map(d => ({ ...d.attributes, id: d.id }))
          setExhibitions(exhibitions)
        })
        .catch((error) => {
          console.log('error', error);
          setExhibitionsError(true);
        });
    }
  }, [language]);

  return [exhibitions, exhibitionsError];
};


export const useFetchAbout = (language) => {
  const [about, setAbout] = useState({});
  const [aboutError, setAboutError] = useState(false);

  useEffect(() => {
    if (language) {
      axios.get(`${API_URL}/about?locale=${language}&populate=*`)
        .then((response) =>
          {
          let about = response.data.data['attributes'];
          about.id = response.data.data.id;
           setAbout(about)
          })
        .catch((error) => {
          console.log('error', error);
          setAboutError(true);
        });
    }
  }, [language]);

  return [about, aboutError];
};

export const useFetchStaticText = (language) => {
  const [staticText, setStaticText] = useState({});
  const [staticTextError, setStaticTextError] = useState(false);

  useEffect(() => {
    if (language) {
      axios.get(`${API_URL}/static-text?locale=${language}&populate=*`)
            .then((response) =>
          {
          let staticText = response.data.data['attributes'];
          staticText.id = response.data.data.id;
           setStaticText(staticText)
          })
        .catch((error) => {
          console.log('error', error);
          setStaticTextError(true);
        });
    }
  }, [language]);

  return [staticText, staticTextError];
};


export const useMedia = (queries, values, defaultValue) => {
  // Array containing a media query list for each query
  const mediaQueryLists = queries.map((q) => typeof window !== 'undefined' && window.matchMedia(q));

  // Function that gets value based on matching media query
  const getValue = () => {
    // Get index of first media query that matches
    const index = mediaQueryLists.findIndex((mql) => mql.matches);
    // Return related value or defaultValue if none
    return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
  };

  // State and setter for matched value
  const [value, setValue] = useState(getValue);

  useEffect(
    () => {
      // Event listener callback
      // Note: By defining getValue outside of useEffect we ensure that it has ...
      // ... current values of hook args (as this hook callback is created once on mount).
      const handler = () => setValue(getValue);
      // Set a listener for each media query with above handler as callback.
      mediaQueryLists.forEach((mql) => mql.addListener(handler));
      // Remove listeners on cleanup
      return () => mediaQueryLists.forEach((mql) => mql.removeListener(handler));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [], // Empty array ensures effect is only run on mount and unmount
  );

  return value;
};
