import React, { useEffect, useRef } from 'react';
import {
  func, number, array, bool,
} from 'prop-types';

import Event from '../Event/Event';

const EventsList = ({
  arr, setSelectedEventId, selectedEventId, evtType, shouldExpandFirstChild, staticText,
}) => (
  arr.map((event, index) => (
    <Event
      shouldExpandByDefault={shouldExpandFirstChild && index === 0}
      key={event.id}
      evtType={evtType}
      setSelectedEventId={setSelectedEventId}
      startTime={event.startTime}
      type={event.type}
      title={event.title}
      description={event.description}
      date={event.date}
      id={event.id}
      endTime={event.endTime}
      name={event.name}
      pictures={event.picture}
      selectedEventId={selectedEventId}
      link={event.link}
      subDescription={event.subDescription}
      staticText={staticText}
    />
  ))
);

const EventsWrapper = ({
  currentEvents,
  upcomingEvents,
  previousEvents,
  setSelectedEventId,
  selectedEventId,
  isSelectedEvent,
  setIsSelectedEvent,
  staticText,
}) => {
  const ref = useRef();
  useEffect(() => {
    if (isSelectedEvent) {
      ref.current.scrollIntoView();
    }
    setIsSelectedEvent(false);
  });

  return (
    <div ref={ref}>
      {!!currentEvents.length && (
        <EventsList
          shouldExpandFirstChild
          evtType={staticText.eventCurrentEvent}
          arr={currentEvents}
          selectedEventId={selectedEventId}
          setSelectedEventId={setSelectedEventId}
          staticText={staticText}
        />
      )}
      {!!upcomingEvents.length && (
        <EventsList
          shouldExpandFirstChild={!currentEvents.length}
          evtType={staticText.eventCurrentUpcommingEvent}
          arr={upcomingEvents}
          selectedEventId={selectedEventId}
          setSelectedEventId={setSelectedEventId}
          staticText={staticText}
        />
      )}
      {!!previousEvents.length && (
        <EventsList
          evtType={staticText.eventPreviousEvent}
          arr={previousEvents}
          selectedEventId={selectedEventId}
          setSelectedEventId={setSelectedEventId}
          staticText={staticText}
        />
      )}
    </div>
  );
};

EventsWrapper.propTypes = {
  currentEvents: array,
  upcomingEvents: array,
  previousEvents: array,
  setSelectedEventId: func.isRequired,
  selectedEventId: number,
  isSelectedEvent: bool.isRequired,
  setIsSelectedEvent: func.isRequired,
};

EventsWrapper.defaultProps = {
  currentEvents: [],
  upcomingEvents: [],
  previousEvents: [],
  selectedEventId: null,
};

export default EventsWrapper;
