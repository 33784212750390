export const API_URL = process.env.NODE_ENV === 'development'
  ? 'https://strapi.ssiimmiiaann.org/api'
  : 'https://strapi.ssiimmiiaann.org/api';

export const BASE_URL = process.env.NODE_ENV === 'development'
  ? 'https://strapi.ssiimmiiaann.org'
  : 'https://strapi.ssiimmiiaann.org';

export const ENGLISH = 'en';
export const DANISH = 'da';

export const DEFAULT_LANGUAGE = ENGLISH;

export const FIVE_SECONDS = 5000;
